import React, { createContext, useContext, useMemo, Fragment, useState, forwardRef, useEffect, useCallback, cloneElement, Children } from 'react';
import { Link as Link$1, Box, jsx, Button, Heading, useColorMode, Themed, Embed, Image, Text, Label, useThemeUI, merge, ThemeProvider as ThemeProvider$1, Flex, Divider, Avatar, Checkbox, Progress, Input, Select } from 'theme-ui';
import { __rest } from 'tslib';
import { LinkIcon, ChevronDownIcon, ChevronRightIcon, SunIcon, MoonIcon, CheckIcon, LocationIcon, InfoIcon, ArrowLeftIcon, ArrowRightIcon, SearchIcon, ThreeBarsIcon, XIcon, TriangleDownIcon, TriangleUpIcon } from '@primer/octicons-react';
import AnimateHeight from 'react-animate-height';
import MarkdownToJSX from 'markdown-to-jsx';
import { preToCodeBlock } from 'mdx-utils';
import Highlight, { defaultProps } from 'prism-react-renderer';
import duotoneDark from 'prism-react-renderer/themes/duotoneDark';
import duotoneLight from 'prism-react-renderer/themes/duotoneLight';
import copy from 'copy-to-clipboard';
import ReactSwitch from 'react-switch';
import TooltipTrigger from 'react-popper-tooltip';
import { get } from '@theme-ui/css';
import md5 from 'md5';
import { resetIdCounter, Tab as Tab$1, TabList as TabList$1, TabPanel as TabPanel$1, Tabs as Tabs$1 } from 'react-tabs';
import scrollIntoView from 'scroll-into-view-if-needed';
import tinycolor from 'tinycolor2';
import { getColor, transparentize } from '@theme-ui/color';
import { mix } from 'polished';
import { useBreakpointIndex } from '@theme-ui/match-media';
import memoize from 'fast-memoize';
import { useTable, useGlobalFilter, useGroupBy, useSortBy, useExpanded, usePagination, useRowSelect } from 'react-table';

/**
 *  returns a sorted and grouped list of the actions
 */
const getSortedActions = (actions) => actions
    .reduce((acc, item) => {
    const accIndex = acc.findIndex(accItem => { var _a, _b; return ((_a = accItem.id) !== null && _a !== void 0 ? _a : accItem.node) === ((_b = item.id) !== null && _b !== void 0 ? _b : item.node); });
    if (accIndex > -1) {
        acc[accIndex] = Object.assign(Object.assign({}, acc[accIndex]), item);
        return acc;
    }
    else {
        return [...acc, item];
    }
}, [])
    .filter(({ hidden }) => !hidden)
    .map((_a, index) => {
    var { order } = _a, item = __rest(_a, ["order"]);
    return (Object.assign(Object.assign({}, item), { order: order !== null && order !== void 0 ? order : index }));
})
    .sort((a, b) => {
    return a.order - b.order;
});
/**
 *  returns a sorted and grouped list of all the actions that have a panel property
 */
const getSortedPanels = (actions) => getSortedActions(actions)
    .filter(action => action.panel)
    .sort((a, b) => {
    return b.order - a.order;
});

/**
 * Anchor link to an external url,
 * adds the default `target="_blank" rel="noopener noreferrer"` props.
 */
const ExternalLink = props => (React.createElement(Link$1, Object.assign({}, props, { target: "_blank", rel: "noopener noreferrer" })));

const LinkContext = createContext(Link$1);
const LinkContextProvider = ({ linkClass: LinkClass, children, }) => {
    return (React.createElement(LinkContext.Provider, { value: (props) => (React.createElement(Box, Object.assign({ variant: "styles.a", as: LinkClass }, props))) }, children));
};
const useGetLinkClass = () => {
    return useContext(LinkContext) || Link$1;
};

const useIsLocalLink = (link) => {
    //https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative/10687158
    const r = useMemo(() => new RegExp('^(?:[a-z]+:)?//', 'i'), []);
    return typeof link === 'string' && !r.test(link);
};

/**
 * Configurable anchor link with a LinkContext. Also checks if the link is intrenal to the site/app or external.
 */
const Link = (props) => {
    const { href } = props;
    const isLocal = useIsLocalLink(href);
    const LinkClass = useGetLinkClass();
    if (!isLocal) {
        return React.createElement(ExternalLink, Object.assign({}, props));
    }
    return React.createElement(LinkClass, Object.assign({}, props));
};

/** @jsx jsx */
/**
 * A strip of actions to be attached to a container.
 * The action items contain the labels and click event handlers.
 * Actions can accept an order prop, and can also overwrite default actions.
 *
 */
const ActionBar = ({ actions = [], themeKey = 'actionbar', }) => {
    const items = useMemo(() => {
        const sortedItems = getSortedActions(actions);
        return sortedItems.map(({ node, onClick, 'aria-label': ariaLabel, group, href }, index) => {
            const nextGroup = index < sortedItems.length - 1 ? sortedItems[index + 1].group : group;
            return (jsx(Box, { key: `${typeof node === 'string' ? node : 'item'}_${index}`, sx: {
                    mr: index === 0 ? 1 : 0,
                    ml: nextGroup !== group || group === undefined ? 2 : 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    variant: `${themeKey}.item`,
                } }, typeof node === 'string' ? (href ? (jsx(Link, { variant: `${themeKey}.link`, href: href, "aria-label": ariaLabel }, node)) : (jsx(Button, { variant: `${themeKey}.button`, onClick: onClick, "aria-label": ariaLabel }, node))) : (node)));
        });
    }, [actions, themeKey]);
    return (jsx(Box, { variant: `${themeKey}.container` },
        jsx(Box, { variant: `${themeKey}.inner` }, items)));
};

/**
 * Container box with actions.
 */
const ActionContainer = ({ children, actions, plain, }) => {
    const hasActions = actions && !!actions.length;
    return (jsx("div", null,
        hasActions && jsx(ActionBar, { actions: actions }),
        plain ? children : jsx(Box, { variant: "actioncontainer" }, children)));
};

/**
 * Animated expand/collapse container component, using [react-animate-height](https://github.com/Stanko/react-animate-height).
 *
 */
const Collapsible = (_a) => {
    var { children, isOpen } = _a, rest = __rest(_a, ["children", "isOpen"]);
    return (React.createElement(AnimateHeight, Object.assign({ height: isOpen ? 'auto' : 0 }, rest), isOpen ? children : ''));
};

const titleToId = (id) => {
    const strId = typeof id === 'string' ? id : '';
    const value = strId.replace(/\W/g, '-').toLowerCase();
    if (value.match(/^[A-Z]/i)) {
        return value;
    }
    //firce start with letter
    return value ? `i-${value}` : value;
};
const pageLink = (id) => {
    let url = '';
    if (typeof window !== 'undefined') {
        if (window.location !== window.parent.location) {
            url = document.referrer;
        }
        else {
            url = document.location.href || '';
        }
    }
    return `${url.split('#')[0]}#${id}`;
};

const iconSize = {
    h1: { size: 28, level: 1 },
    h2: { size: 26, level: 2 },
    h3: { size: 18, level: 3 },
    h4: { size: 16, level: 4 },
    h5: { size: 14, level: 5 },
    h6: { size: 12, level: 6 },
};
/**
 * h1-h6 heading component that generates automatically a github-style anchor to navigate to a section on hover.
 */
const LinkHeading = (_a) => {
    var { as = 'h3', id, children, title } = _a, rest = __rest(_a, ["as", "id", "children", "title"]);
    const { size, level } = iconSize[as];
    const padding = 4;
    const childStrings = Array.isArray(children)
        ? children
            .map(child => {
            var _a;
            return typeof child === 'string'
                ? child
                : typeof child === 'object'
                    ? (_a = child.props) === null || _a === void 0 ? void 0 : _a.children
                    : null;
        })
            .filter(child => child)
        : children;
    const linkId = titleToId(id || childStrings);
    const label = title ||
        (Array.isArray(childStrings)
            ? childStrings
                .map(c => typeof c === 'string' ? c : Array.isArray(c) ? c.join(' ') : '')
                .join(' ')
            : typeof childStrings === 'string'
                ? childStrings
                : linkId);
    return (jsx(Box, { id: linkId, variant: "linkheading.container" },
        jsx(Box, { variant: "linkheading.inner" },
            (id || typeof children === 'string') && (jsx(Link$1, { sx: { paddingRight: `${padding}px`, left: -(size + padding) }, variant: "linkheading.link", href: pageLink(linkId), "aria-label": `navigate to ${label} section`, "data-title": label, "data-id": linkId, "data-level": level },
                jsx(LinkIcon, { size: size, verticalAlign: "middle" }))),
            children && (jsx(Heading, Object.assign({ as: as, variant: `styles.${as}` }, rest), children)))));
};

/** @jsx jsx */
// from lekoarts gatsby themes
// https://github.com/LekoArts/gatsby-themes/blob/master/themes/gatsby-theme-minimal-blog/src/components/code.tsx#L34
const RE = /{([\d,-]+)}/;
const calculateLinesToHighlight = (meta) => {
    if (!RE.test(meta)) {
        return () => false;
    }
    const lineNumbers = RE.exec(meta)[1]
        .split(`,`)
        .map(v => v.split(`-`).map(x => parseInt(x, 10)));
    return (index) => {
        const lineNumber = index + 1;
        const inRange = lineNumbers.some(([start, end]) => end ? lineNumber >= start && lineNumber <= end : lineNumber === start);
        return inRange;
    };
};
/**
 * Syntax highlighter component using [prism](https://prismjs.com) for the actual source display.
 */
const SyntaxHighlighter = ({ children = '', language = 'jsx', theme: customTheme, renderFn, dark = false, style: propStyle, title, metastring = ``, as = 'span', }) => {
    const [colorMode] = useColorMode();
    const isDark = dark === true || colorMode === `dark`;
    const theme = customTheme ? customTheme : isDark ? duotoneDark : duotoneLight;
    const shouldHighlightLine = calculateLinesToHighlight(metastring);
    const renderProps = typeof renderFn === 'function'
        ? (props) => renderFn(props, { theme })
        : ({ className, style, tokens, getLineProps, getTokenProps }) => (jsx(Fragment, null,
            title && (jsx(Heading, { as: "h3", variant: "syntaxhighlight.title" }, title)),
            jsx(Themed.pre, { className: `${className}`, style: Object.assign(Object.assign(Object.assign({}, style), { padding: '3px 5px', display: 'inline', margin: 0 }), propStyle) }, tokens.map((line, i) => {
                const highlight = shouldHighlightLine(i);
                return (jsx(Box, Object.assign({ as: as, variant: `syntaxhighlight.${highlight ? 'highlight' : 'normal'}` }, getLineProps({ line, key: i })), line.map((token, key) => (jsx("span", Object.assign({}, getTokenProps({ token, key }), { sx: { display: 'inline-block' } }))))));
            }))));
    const props = Object.assign(Object.assign({}, defaultProps), { theme });
    return (jsx(Highlight, Object.assign({}, props, { code: children, language: language }), renderProps));
};

/**
 * Syntax highliting source code component. Uses [prism](https://prismjs.com) for the actual source display.
 *
 */
const Source = (_a) => {
    var { children = '', actions, as = 'div' } = _a, props = __rest(_a, ["children", "actions", "as"]);
    const [copied, setCopied] = useState(false);
    const onCopy = (e) => {
        e.preventDefault();
        copy(children);
        if (typeof window !== 'undefined') {
            setCopied(true);
            window.setTimeout(() => setCopied(false), 1500);
        }
    };
    const actionsItems = [
        {
            node: copied ? 'copied' : 'copy',
            onClick: onCopy,
            id: 'copy',
            'aria-label': 'copy the displayed source code',
        },
        ...(Array.isArray(actions) ? [...actions] : []),
    ];
    return (jsx(ActionContainer, { actions: actionsItems },
        jsx(SyntaxHighlighter, Object.assign({ as: as }, props, { style: {
                padding: '25px 10px 10px',
                display: 'block',
            } }), children)));
};

/**
 * Image component with a title if available
 */
const TitledImage = props => {
    const { title, src } = props;
    const youTbeRegEx = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})?$/;
    const isYouTube = src && youTbeRegEx.test(src);
    const img = isYouTube ? (React.createElement(Embed, { src: src })) : (React.createElement(Image, Object.assign({ variant: "titledimage.image", src: src }, props)));
    return title ? (React.createElement(Box, { variant: "titledimage.container" },
        img,
        title && React.createElement(Text, { variant: "titledimage.title" }, title))) : (img);
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
const mdxLanguageMap = {
    bash: 'bash',
    c: 'c',
    cpp: 'cpp',
    'C++': 'cpp',
    css: 'css',
    js: 'javascript',
    jsx: 'jsx',
    JSX: 'jsx',
    'coffee-script': 'coffeescript',
    coffeescript: 'coffeescript',
    coffee: 'coffeescript',
    actionscript3: 'actionscript',
    diff: 'diff',
    go: 'go',
    json: 'json',
    less: 'less',
    make: 'makefile',
    Makefile: 'makefile',
    markdown: 'markdown',
    mdx: 'jsx',
    objectivec: 'objectivec',
    python: 'python',
    scss: 'scss',
    sql: 'sql',
    yaml: 'yaml',
};
const paramsFromClassName = (className = ``) => {
    const [lang = ``, params = ``] = className.split(`:`);
    return [
        // @ts-ignore
        lang.split(`language-`).pop().split(`{`).shift(),
    ].concat(
    // @ts-ignore
    params.split(`&`).reduce((merged, param) => {
        const [key, value] = param.split(`=`);
        // @ts-ignore
        merged[key] = value;
        return merged;
    }, {}));
};
const markdownComponents = {
    code: props => {
        return React.createElement(SyntaxHighlighter, Object.assign({}, props));
    },
    p: props => {
        var _a;
        return ((_a = props.children) === null || _a === void 0 ? void 0 : _a.length) === 1 && typeof props.children[0] === 'string' ? (React.createElement("p", Object.assign({}, props))) : (React.createElement("div", Object.assign({ variant: "styles.p" }, props)));
    },
    pre: props => {
        const mdxProps = preToCodeBlock(props);
        if (!mdxProps) {
            return React.createElement("pre", Object.assign({}, props));
        }
        const { codeString = '', metastring, className } = mdxProps;
        const [language, ...rest] = paramsFromClassName(className);
        const otherProps = Array.isArray(rest)
            ? rest.reduce((acc, p) => typeof p === 'object'
                ? Object.assign(Object.assign({}, acc), p) : acc, {})
            : undefined;
        return (React.createElement(Box, { variant: "syntaxhighlight.markdown" },
            React.createElement(Source, Object.assign({ language: mdxLanguageMap[language || 'jsx'] || language, metastring: metastring }, otherProps), codeString.trimRight())));
    },
    h1: props => React.createElement(LinkHeading, Object.assign({ as: "h1" }, props)),
    h2: props => React.createElement(LinkHeading, Object.assign({ as: "h2" }, props)),
    h3: props => React.createElement(LinkHeading, Object.assign({ as: "h3" }, props)),
    h4: props => React.createElement(LinkHeading, Object.assign({ as: "h4" }, props)),
    h5: props => React.createElement(LinkHeading, Object.assign({ as: "h5" }, props)),
    h6: props => React.createElement(LinkHeading, Object.assign({ as: "h6" }, props)),
    img: TitledImage,
    button: Button,
    label: Label,
    summary: props => React.createElement("summary", Object.assign({ variant: "styles.summary" }, props)),
    details: props => React.createElement("details", Object.assign({ variant: "styles.details" }, props)),
};

/**
 * Markdown display component to compile and display markdown at run-time.
 * Uses [markdown-to-jsx](https://github.com/probablyup/markdown-to-jsx) internally.
 */
const Markdown = (_a) => {
    var { children, components } = _a, rest = __rest(_a, ["children", "components"]);
    return (jsx(MarkdownToJSX, Object.assign({ options: {
            forceBlock: true,
            overrides: Object.assign(Object.assign({}, markdownComponents), components),
        } }, rest), children));
};

const text = 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif';
const heading = {
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontWeight: 'heading',
};
const ActionItemStyle = {
    color: 'background',
    backgroundColor: 'action',
    //safari fix:
    WebkitTextFillColor: 'initial',
    px: 2,
    py: 1,
    lineHeight: 1,
    borderRadius: 1,
    display: 'inline-block',
    boxShadow: (t) => { var _a, _b; return `${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow} 0 1px 3px 1px, ${(_b = t.colors) === null || _b === void 0 ? void 0 : _b.shadow} 0 0 0 1px`; },
    border: (t) => { var _a; return `1px solid ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.action}`; },
};
const PageContainerDefaults = {
    bg: 'background',
    color: 'text',
    fontFamily: 'body',
    flex: '1 0 auto',
    py: 4,
    px: [2, 3, 5],
    margin: 'auto',
    width: '100%',
    position: 'relative',
    maxWidth: '1000px',
};
const theme = {
    useCustomProperties: false,
    colors: {
        text: '#454f5b',
        background: '#ffffff',
        primary: '#5c6ac4',
        secondary: '#006fbb',
        muted: '#e6e6e6',
        mutedText: '#69768C',
        accent: '#f49342',
        darken: '#00044c',
        gray: '#f6f6f6',
        highlight: '#d9f2f1',
        action: '#3B817D',
        selected: '#027AC5',
        shadow: 'rgba(0, 0, 0, 0.1)',
        accentPalette0: '#8338ec',
        accentPalette1: '#fb5607',
        accentPalette2: '#ff006e',
        accentPalette3: '#ffbe0b',
        accentPalette4: '#3a86ff',
        accentPalette5: '#4ecdc4',
        palette0: '#4caf50',
        palette1: '#2196f3',
        palette2: '#ff9800',
        palette3: '#f44336',
        palette4: '#dc004e',
        palette5: '#1976d2',
        status_passed: '#4caf50',
        status_failed: '#f44336',
        status_skipped: '#fab305',
        status_pending: '#fce83a',
        status_todo: '#39ccff',
        status_disabled: '#9ea7ad',
        modes: {
            dark: {
                primary: '#efefef',
                secondary: '#b4e1fa',
                highlight: '#b7ecec',
                muted: '#262626',
                mutedText: '#c9cacf',
                gray: '#4d5866',
                background: '#38404a',
                text: '#d3d4db',
                selected: '#b3d9ff',
                action: '#d9f2f1',
                shadow: 'rgba(211, 212, 219, 0.1)',
            },
        },
    },
    fonts: {
        body: text,
        heading: 'medium-content-title-font,Georgia,Cambria,"Times New Roman",Times,serif',
        monospace: 'Menlo, monospace',
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 42, 48, 64, 96],
    fontWeights: {
        thin: 300,
        body: 400,
        heading: 600,
        bold: 700,
    },
    lineHeights: {
        body: 1.75,
        heading: 1.25,
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    buttons: {
        plain: {
            p: 0,
            lineHeight: 'normal',
            backgroundColor: 'transparent',
            color: 'primary',
        },
        primary: {
            color: '#333',
            backgroundColor: '#f3f3f3',
            borderRadius: '5px',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset',
            ':disabled': {
                color: '#aaa',
            },
        },
        secondary: {
            backgroundColor: 'action',
        },
    },
    cards: {
        primary: {
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            borderRadius: 8,
            border: '1px solid rgba(0, 0, 0, 0.125)',
            ':hover': {
                border: '1px solid rgba(0, 0, 0, 0.25)',
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
            },
        },
    },
    links: {
        nav: {
            fontWeight: 'thin',
            fontSize: 3,
            lineHeight: '1.6rem',
            '&.active': {
                fontWeight: 'bold',
                color: 'primary',
            },
        },
    },
    text: {
        small: {
            fontSize: 1,
            fontWeight: 'thin',
        },
    },
    forms: {
        checkbox: {
            cursor: 'pointer',
            border: (t) => { var _a; return `1px solid ${(_a = t === null || t === void 0 ? void 0 : t.colors) === null || _a === void 0 ? void 0 : _a.text}`; },
            '&:focus': {
                backgroundColor: 'transarent',
                boxShadow: (t) => { var _a; return `0 0 0 2px ${(_a = t === null || t === void 0 ? void 0 : t.colors) === null || _a === void 0 ? void 0 : _a.primary}`; },
                outline: 'none',
            },
        },
        slider: {
            label: {
                paddingLeft: 1,
                paddingRight: 1,
                fontSize: 0,
                whiteSpace: 'nowrap',
            },
            wrapper: {
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            },
        },
        input: {
            p: 1,
        },
        textarea: {
            p: 1,
        },
    },
    styles: {
        root: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
        },
        h1: Object.assign(Object.assign({}, heading), { fontSize: 6, my: 4 }),
        h2: Object.assign(Object.assign({}, heading), { fontSize: 5, my: 3, borderBottom: (t) => { var _a; return `4px solid ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.text}`; }, width: '100%' }),
        h3: Object.assign(Object.assign({}, heading), { my: 3 }),
        h4: Object.assign(Object.assign({}, heading), { fontSize: 2, my: 3 }),
        h5: Object.assign(Object.assign({}, heading), { fontSize: 1, my: 2 }),
        h6: Object.assign(Object.assign({}, heading), { fontSize: 0, my: 2 }),
        a: {
            color: 'primary',
            transition: `all 0.3s ease-in-out`,
        },
        pre: {
            variant: 'prism',
            fontFamily: 'monospace',
            overflowX: 'auto',
            bg: 'muted',
            code: {
                color: 'inherit',
            },
        },
        code: {
            fontSize: 'inherit',
            color: 'accent',
        },
        inlineCode: {
            fontFamily: 'monospace',
            color: 'secondary',
            bg: 'muted',
        },
        blockquote: {
            borderLeft: (t) => { var _a; return `4px solid ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
            pl: 4,
            m: 0,
        },
        hr: {
            textAlign: 'center',
            overflow: 'visible',
            border: 'none',
            height: 0,
            ':before': {
                content: '"..."',
                display: 'inline-block',
                marginLeft: '.6em',
                color: 'mutedText',
                position: 'relative',
                top: `-36px`,
                letterSpacing: '.6em',
                fontSize: 5,
            },
        },
        img: {
            maxWidth: '100%',
        },
        p: {
            fontSize: 3,
            my: 3,
            color: 'text',
            fontFamily: 'body',
            fontWeight: 'body',
            lineHeight: 'body',
        },
        summary: {
            fontSize: 3,
            my: 3,
            cursor: 'pointer',
        },
        ol: {
            fontSize: 3,
        },
        ul: {
            fontSize: 3,
            py: 2,
        },
        table: {
            margin: 0,
            borderCollapse: 'collapse',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'left',
            width: '100%',
            borderSpacing: 0,
            p: {
                m: 0,
            },
            pre: {
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                maxWidth: '240px',
                bg: 'inherit',
                mt: 2,
                mb: 0,
            },
            a: {
                wordBreak: 'break-word',
            },
        },
        th: {
            border: 'none',
            py: 3,
            pr: 2,
            pl: 3,
        },
        tbody: {
            'tr:last-of-type': {
                borderBottom: 0,
            },
        },
        thead: {
            borderBottom: (t) => { var _a; return ` 2px solid  ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
            backgroundColor: 'background',
            color: 'text',
        },
        td: {
            py: 2,
            px: 3,
            borderBottom: 0,
        },
        tdgroup: {
            lineHeight: '24px',
            bg: 'gray',
            whiteSpace: 'nowrap',
            py: 3,
            fontWeight: 'bold',
            fontFamily: 'monospace',
            flexDirection: 'row',
            alignItems: 'center',
        },
        tr: {
            borderBottom: (t) => { var _a; return ` 1px solid  ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
        },
    },
    actionbar: {
        container: {
            position: 'relative',
        },
        inner: {
            position: 'absolute',
            display: 'flex',
            width: '100%',
            flexDirection: 'row-reverse',
            marginLeft: 'auto',
        },
        item: {
            mt: 1,
            fontSize: 1,
            a: ActionItemStyle,
            button: ActionItemStyle,
        },
    },
    toolbar: {
        inner: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        link: {
            mr: 1,
        },
    },
    footer: {
        inner: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        link: {
            mr: 1,
        },
    },
    actioncontainer: {
        boxShadow: (t) => { var _a; return `0px 1px 3px 0px ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
        border: (t) => { var _a; return ` 1px solid  ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
        display: 'flex',
        flexDirection: 'column',
    },
    blockcontainer: {
        container: {
            position: 'relative',
            mt: 4,
            mb: 4,
            width: '100%',
            scrollMarginTop: '5rem',
        },
        inner: {
            boxShadow: (t) => { var _a; return `0px 1px 3px 0px ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
            border: (t) => { var _a; return ` 1px solid  ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
            flexDirection: 'row',
            overflowX: 'auto',
            alignItems: 'center',
            ':hover': {
                a: {
                    visibility: 'visible',
                },
            },
        },
        link: {
            position: 'absolute',
            left: -4,
            px: 2,
            pb: 2,
            visibility: 'hidden',
            ':hover': {
                visibility: 'visible',
            },
        },
        titleblock: { flexDirection: 'row', alignItems: 'center' },
        expandicon: { ml: 2, pb: 2 },
    },
    description: {
        p: {
            my: 2,
        },
    },
    blockpagecontainer: {
        container: {},
        inforow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        secondrow: {
            pt: 2,
        },
        titlerow: {
            mt: 4,
        },
        createdbox: {
            container: {
                display: 'flex',
                flexDirection: ['column', 'row'],
                alignItems: ['flex-end', 'baseline'],
                justifyContent: 'space-between',
            },
        },
    },
    linkheading: {
        container: {
            width: '100%',
            scrollMarginTop: '5rem',
        },
        inner: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            ':hover': {
                a: {
                    visibility: 'visible',
                },
            },
        },
        link: {
            position: 'absolute',
            margin: 'auto',
            top: 0,
            bottom: 0,
            height: '32px',
            visibility: 'hidden',
            ':hover': {
                visibility: 'visible',
            },
        },
    },
    multiselect: {
        container: {
            px: 3,
            py: 1,
        },
        item: {
            py: 1,
        },
    },
    searchinput: {
        popover: {
            minWidth: 300,
            maxHeight: 400,
            maxWidth: 500,
            overflowY: 'auto',
        },
        list: {
            py: 2,
            paddingLeft: 0,
            listStyle: 'none',
            em: {
                fontStyle: 'normal',
                bg: 'primary',
                color: 'background',
            },
        },
        item: {
            px: 1,
            cursor: 'pointer',
            ':hover': {
                backgroundColor: 'shadow',
            },
            '&.active': {
                backgroundColor: 'shadow',
                fontWeight: 'bold',
                color: 'primary',
                border: (t) => { var _a; return `1px solid ${(_a = t === null || t === void 0 ? void 0 : t.colors) === null || _a === void 0 ? void 0 : _a.primary}`; },
            },
        },
    },
    subtitle: {
        color: 'mutedText',
        fontWeight: 'body',
        pb: 2,
    },
    subheading: {
        fontWeight: 'body',
        pb: 1,
    },
    syntaxhighlight: {
        markdown: {
            my: 2,
        },
        highlight: {
            pl: 1,
            backgroundColor: 'highlight',
            borderLeft: (t) => { var _a; return `4px solid ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.primary}`; },
        },
        normal: {},
        title: {
            width: 'inherit',
            background: 'transparent',
            p: 2,
            fontSize: 2,
        },
    },
    tabs: {
        '.react-tabs': {
            WebkitTapHighlightColor: 'transparent',
        },
        '.react-tabs__tab-list': {
            margin: '0 0 10px',
            padding: 0,
        },
        '.react-tabs__tab': {
            fontSize: 2,
            fontWeight: 'bold',
            display: 'inline-block',
            borderBottom: 'none',
            bottom: -1,
            position: 'relative',
            listStyle: 'none',
            padding: '4px 10px',
            ml: 1,
            mr: 1,
            cursor: 'pointer',
            color: 'mutedText',
            a: {
                textDecoration: 'inherit',
                color: 'inherit',
            },
            ':focus': {
                boxShadow: '0 0 5px hsl(208, 99%, 50%)',
                borderColor: 'hsl(208, 99%, 50%)',
                outline: 'none',
                ':after': {
                    content: '""',
                    position: 'absolute',
                    height: '5px',
                    left: '-4px',
                    right: '-4px',
                    bottom: '-5px',
                    background: '#fff',
                },
            },
        },
        '.react-tabs__tab--selected': {
            borderBottom: (t) => { var _a; return `3px solid ${(_a = t === null || t === void 0 ? void 0 : t.colors) === null || _a === void 0 ? void 0 : _a.primary}`; },
            color: 'primary',
        },
        '.react-tabs__tab--disabled': {
            color: 'mutedText',
            cursor: 'default',
        },
        '.react-tabs__tab-panel': {
            display: 'none',
        },
        '.react-tabs__tab-panel--selected': {
            display: 'block',
        },
    },
    table: {
        pagination: {
            container: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'background',
                py: 2,
            },
            total: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                px: 3,
            },
            navigationContainer: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            },
            navigation: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                px: 2,
                button: {
                    width: '50px',
                    mx: '2px',
                },
            },
            page: {
                px: 2,
            },
            pagesize: {
                px: 2,
                width: '140px',
            },
            interactive: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                px: 2,
                input: {
                    ml: 1,
                    width: '100px',
                },
            },
        },
    },
    shield: {
        container: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: 0,
            alignItems: 'center',
        },
        left: {
            px: 1,
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
        },
        right: {
            px: 1,
            borderTopRightRadius: 3,
            borderBottomRightRadius: 3,
        },
    },
    tag: {
        default: {
            display: 'inline-block',
            px: 1,
            borderRadius: 5,
            whiteSpace: 'nowrap',
        },
        rightmargin: {
            mr: 1,
            my: '2px',
            display: 'inline-block',
            px: 1,
            whiteSpace: 'nowrap',
        },
        leftmargin: {
            ml: 1,
            mb: 1,
            display: 'inline-block',
            px: 1,
            whiteSpace: 'nowrap',
        },
        small: {
            ml: 1,
            display: 'inline-block',
            fontSize: 0,
            lineHeight: '1rem',
            whiteSpace: 'nowrap',
        },
    },
    titledimage: {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        img: {},
        title: {
            color: 'mutedText',
            py: 2,
            fontSize: 1,
        },
    },
    value: {
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
        },
        label: {
            fontSize: 0,
            color: 'mutedText',
            mr: 1,
            lineHeight: 'heading',
        },
        value: {
            fontSize: 2,
            lineHeight: 'heading',
        },
    },
    zoom: {
        position: 'relative',
        transformOrigin: 'top left',
        transition: 'transform .2s',
    },
    editpage: {
        container: {
            lineHeight: 'heading',
        },
        inner: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        text: {
            pl: 1,
            fontSize: 2,
            fontWeight: 'bold',
        },
    },
    pagecontainer: {
        default: Object.assign({}, PageContainerDefaults),
        full: Object.assign(Object.assign({}, PageContainerDefaults), { maxWidth: 'unset', p: [0, 0, 0] }),
    },
    playground: {
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            px: 4,
            my: 4,
        },
    },
    propstable: {
        name: {
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
        },
        'name-deprecated': {
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
            textDecoration: 'line-through',
            color: 'mutedText',
        },
        defaultvalue: {
            maxWidth: 400,
            whiteSpace: 'pre-wrap',
        },
        control: {
            maxWidth: 300,
        },
        description: {
            container: {
                display: 'flex',
                flexDirection: 'column',
            },
            type: {
                color: 'mutedText',
                letterSpacing: '0.10em',
                whiteSpace: 'pre-wrap',
                margin: 0,
            },
        },
    },
    story: {
        container: {
            px: 4,
            py: 3,
        },
        wrapper: {
            all: 'unset',
        },
    },
    colormode: {
        container: {},
        outericon: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%',
        },
        innericon: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
        },
    },
    header: {
        top: 0,
        left: 'auto',
        right: 0,
        zIndex: 10,
        position: 'sticky',
        backgroundColor: 'background',
        px: 2,
        mb: 1,
        justifyContent: `space-between`,
        flexDirection: 'row',
        alignItems: 'center',
        boxShadow: (t) => { var _a; return `0 1px 3px 1px ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
    },
    hoverbox: {
        container: {
            minHeight: '100%',
            minWidth: 'unset',
            pr: 4,
            ':hover': {
                '& > .hoverbox-text': {
                    color: 'mutedText',
                },
            },
        },
        inner: {
            boxShadow: (t) => { var _a; return `0px 2px 6px 0px ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
        },
        text: { color: 'muted', fontWeight: 'bold' },
    },
    infotip: {
        container: {
            maxHeight: '500px',
            maxWidth: '400px',
            overflow: 'auto',
            p: 2,
        },
    },
    tree: {
        itemcontainer: {
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
            width: '100%',
            py: 1,
            justifyContent: 'space-between',
        },
        link: {
            boxShadow: 'none',
            background: 'none',
            textDecoration: 'none',
            cursor: 'pointer',
            color: 'primary',
            px: 1,
            py: 0,
            flex: 1,
            ':hover': {
                backgroundColor: 'shadow',
            },
        },
        labelcontainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        labelicon: {
            mr: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        labeltext: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        expandicon: {},
    },
    pagination: {
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            p: 2,
        },
        pagecontainer: {},
        link: {
            textDecoration: 'none',
        },
        prev: {
            display: 'flex',
            flexDirection: 'column',
            py: 2,
        },
        next: {
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            py: 2,
        },
        label: {
            fontSize: 1,
            color: 'mutedText',
            pb: 2,
        },
        linktitle: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: 3,
            color: 'primary',
            fontWeight: 'bold',
        },
        pagetitle: {
            px: 3,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '300px',
        },
    },
    componentstats: {
        container: {
            display: 'flex',
            flexDirection: ['column', 'row'],
            alignItems: ['baseline', 'center'],
            my: [-1, 1],
            mx: [0, -1],
            '>div': {
                mx: [0, 1],
                my: [1, 0],
            },
        },
    },
    sidebar: {
        default: {
            overflowX: 'hidden',
            position: 'sticky',
            top: '4.5rem',
            minWidth: '150px',
            maxWidth: '300px',
            overflowY: 'auto',
            backgroundColor: 'background',
        },
        responsive: {
            overflowX: 'hidden',
            backgroundColor: 'background',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            width: '100%',
            zIndex: 9999,
            position: 'absolute',
        },
        inner: {
            '.selected': {
                borderLeft: (t) => { var _a; return `4px solid ${(_a = t === null || t === void 0 ? void 0 : t.colors) === null || _a === void 0 ? void 0 : _a.accent}`; },
                fontWeight: 'bold',
                pl: 1,
            },
        },
        headercontainer: {
            pb: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        heading: {
            pl: 2,
        },
    },
    skiplinks: {
        container: {
            display: 'flex',
            border: (t) => { var _a; return `1px solid ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.primary}`; },
            clip: `react(0 0 0 0)`,
            width: '0.01em',
            height: '0.01em',
            whiteSpace: 'nowrap',
            padding: 0,
            overflow: `hidden`,
            position: `absolute`,
            flexDirection: 'column',
            '&:focus-within': {
                padding: 3,
                position: `fixed`,
                top: `50px`,
                left: `15px`,
                backgroundColor: `background`,
                zIndex: 15,
                width: `auto`,
                height: `auto`,
                clip: `auto`,
                textDecoration: `none`,
            },
        },
        item: {},
    },
    app: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
    },
    sidecontext: {
        container: {
            px: 3,
        },
        nav: {
            display: 'flex',
            flexDirection: 'column',
            a: {
                ':hover': {
                    textDecoration: 'underline',
                },
            },
        },
        navlink: {
            '1': {
                fontSize: 2,
                pt: 2,
            },
            '2': {
                fontSize: 1,
                fontWeight: 'body',
                pl: 1,
                ':hover': {
                    textDecoration: 'underline',
                },
            },
            '3': {
                fontSize: 0,
                color: 'mutedText',
                fontWeight: 'body',
                pl: 3,
                ':hover': {
                    color: 'primary',
                    textDecoration: 'underline',
                },
            },
        },
        toggle: {
            position: 'fixed',
            right: '1rem',
            bottom: '3rem',
            backgroundColor: 'gray',
        },
    },
    appsidebar: {
        inner: {
            display: 'flex',
            flexDirection: 'column',
        },
        item: {
            ml: 0,
        },
        items: {
            my: 2,
        },
        sidebar: {
            height: '100vh',
            borderRight: (t) => { var _a; return `1px solid ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            px: 2,
            mb: 2,
        },
    },
    appheader: {
        title: {
            textDecoration: 'none',
            fontWeight: 'normal',
            ':hover': { color: `secondary` },
        },
        logo: {
            maxHeight: 30,
            width: 'auto',
            objectFit: 'cover',
        },
        items: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: `secondary`,
            a: {
                color: `secondary`,
                ':hover': { color: `accent` },
                fontWeight: 'bold',
            },
            py: 3,
        },
    },
    appfooter: {
        container: {
            borderTop: (t) => { var _a; return ` 1px solid  ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
            fontWeight: 'bolder',
            display: 'flex',
            p: 3,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    categorylist: {
        pagecontainer: Object.assign(Object.assign({}, PageContainerDefaults), { maxWidth: '1000px' }),
        list: {},
        item: { my: 2 },
    },
    pagelist: {
        container: Object.assign(Object.assign({}, PageContainerDefaults), { maxWidth: '1000px' }),
    },
    categorypage: {
        pagecontainer: Object.assign(Object.assign({}, PageContainerDefaults), { maxWidth: '1000px' }),
        titlecontainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        listcontainer: { my: 3 },
        mdxcontainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    documentitem: {
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        imageBox: {
            width: '180px',
            height: '180px',
            py: 2,
            pr: 3,
            display: 'flex',
            alignItems: 'center',
        },
        image: {
            objectFit: 'cover',
            border: (t) => { var _a; return ` 1px solid  ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
        },
        content: {
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            py: 2,
            p: {
                mt: 0,
                mb: 2,
            },
        },
        titlerow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        info: {
            container: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
            inner: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            },
            date: {
                color: 'mutedText',
            },
            comma: { mr: 2 },
            by: { mr: 1 },
            author: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            },
        },
    },
    taglist: {
        container: {
            maxWidth: '400px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
        },
    },
    appsidebarpage: {
        allsidebar: {
            display: 'flex',
            flexDirection: 'row',
            flex: '1 0 auto',
            position: 'relative',
        },
        navsidebar: {
            display: 'flex',
            flexDirection: 'row',
            flex: '1 0 auto',
            minHeight: '100vh',
            position: 'relative',
        },
        contextsidebar: {
            display: 'flex',
            flexDirection: 'row',
            flex: '1 0 auto',
            minHeight: 'calc(100vh - 68px)',
            position: 'relative',
        },
    },
    apperror: {
        bg: 'black',
        color: 'red',
        p: 4,
        fontFamily: 'monospace',
    },
    container: {
        container: {},
        pagination: { py: 4 },
        author: { ml: [0, 2] },
        tags: { display: 'flex', flexDirection: 'column', alignItems: ' flex-end' },
    },
    documentslist: {
        container: {},
        sortrow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            pb: 3,
        },
        sortlabel: { width: 'unset', pr: 2 },
        sortselect: { minWidth: '300px' },
        list: {
            listStyle: 'none',
            p: 0,
        },
        listitem: {
            borderBottom: (t) => { var _a; return ` 1px solid  ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
        },
    },
};
const useTheme = () => {
    const { theme: currentTheme } = useThemeUI();
    return currentTheme || theme;
};
const paletteColorCount = 6;
const getPaletteColor = (index) => theme.colors[`palette${index % paletteColorCount}`];
const getAccentPaletteColor = (index) => theme.colors[`accentPalette${index % paletteColorCount}`];

const ThemeContext = createContext({});
const ThemeProvider = ({ theme: customTheme, children, components = {}, }) => {
    const theme$1 = useMemo(() => {
        return customTheme ? merge(theme, customTheme) : theme;
    }, [customTheme]);
    return (React.createElement(ThemeContext.Provider, { value: {
            theme: theme$1,
        } },
        React.createElement(ThemeProvider$1, { theme: theme$1, components: Object.assign(Object.assign({}, markdownComponents), components) }, children)));
};

/**
 * Markdown content with custom components - for example `<p />` is with smaller margin than regular markdown content.
 */
const Description = props => {
    const theme = useTheme();
    return (jsx(Markdown, Object.assign({ components: {
            p: props => { var _a; return jsx("p", Object.assign({ sx: Object.assign({}, (((_a = theme.description) === null || _a === void 0 ? void 0 : _a.p) || {})) }, props)); },
        } }, props)));
};

/**
 * Collapsible block container with a title. The title creates also an attribute id and an octicon for github style navigation.
 *
 */
const BlockContainer = (_a) => {
    var { children, title, id, description, collapsible = true, plain = false } = _a, rest = __rest(_a, ["children", "title", "id", "description", "collapsible", "plain"]);
    const [isOpen, setIsOpen] = useState(true);
    const blockId = id !== '.' ? id || title : title;
    const content = !plain ? (jsx(Box, { variant: "blockcontainer.inner" }, children)) : (children);
    return (jsx(Box, Object.assign({ variant: "blockcontainer.container" }, rest),
        (blockId || title || collapsible) && (jsx(LinkHeading, { as: collapsible ? 'h3' : 'h4', id: blockId, title: title, sx: { my: 0, py: 1 } }, title &&
            (collapsible ? (jsx(Flex, { variant: "blockcontainer.titleblock" },
                title,
                jsx(Link$1, { "aria-expanded": isOpen, sx: {
                        cursor: 'pointer',
                    }, onClick: () => setIsOpen(!isOpen) },
                    jsx(Text, { variant: "blockcontainer.expandicon" }, isOpen ? (jsx(ChevronDownIcon, { verticalAlign: "middle" })) : (jsx(ChevronRightIcon, { verticalAlign: "middle" })))))) : (title)))),
        description && jsx(Description, null, description),
        collapsible ? (jsx(Collapsible, { isOpen: isOpen }, content)) : (content),
        !isOpen && jsx(Divider, null)));
};

/**
 * Toggle components can be used to edit boolean values. Uses [react-switch](https://github.com/markusenglund/react-switch) component.
 *
 */
const Toggle = forwardRef(function Toggle(_a, ref) {
    var { checked = false, onChange, label, id } = _a, rest = __rest(_a, ["checked", "onChange", "label", "id"]);
    const toggle = (jsx(ReactSwitch, Object.assign({ "aria-checked": checked, ref: ref, id: id, checked: checked, onChange: onChange, "aria-label": `click to ${checked ? 'un-' : ''}select` }, rest)));
    if (label) {
        return (jsx(Label, { htmlFor: id },
            jsx(Text, { sx: { mx: 2 } }, label),
            toggle));
    }
    return toggle;
});
Toggle.displayName = 'Toggle';

/** @jsx jsx */
/**
 * Theme-ui dark/light mode toggle.
 */
const ColorMode = props => {
    const [colorMode, setColorMode] = useColorMode();
    const isDark = colorMode === `dark`;
    const toggleColorMode = (checked) => {
        setColorMode(checked ? `dark` : `light`);
    };
    return (jsx(Box, { variant: "colormode.container" },
        jsx(Toggle, Object.assign({ "aria-label": "Toggle dark mode", uncheckedIcon: jsx(Box, { variant: "colormode.outericon" },
                jsx(Box, { variant: "colormode.innericon" },
                    jsx(SunIcon, null))), checkedIcon: jsx(Box, { variant: "colormode.outericon" },
                jsx(Box, { variant: "colormode.innericon" },
                    jsx(MoonIcon, null))), checked: isDark, onChange: toggleColorMode, onColor: "#333", offColor: "#ddd" }, props))));
};

const SPACING = 8;
const match = (requested, actual, value, fallback = 0) => (actual.split('-')[0] === requested ? value : fallback);
const matchPx = (requested, actual, value, fallback = 0) => {
    const result = match(requested, actual, value, fallback);
    return typeof result === 'number' ? `${result}px` : result;
};
const Arrow = forwardRef(function Arrow(_a, ref) {
    var { placement, borderColor } = _a, rest = __rest(_a, ["placement", "borderColor"]);
    return (jsx(Box, Object.assign({ sx: {
            position: 'absolute',
            borderStyle: 'solid',
            bg: 'background',
            marginBottom: matchPx('top', placement, 0, SPACING),
            marginTop: matchPx('bottom', placement, 0, SPACING),
            marginRight: matchPx('left', placement, 0, SPACING),
            marginLeft: matchPx('right', placement, 0, SPACING),
            bottom: matchPx('top', placement, SPACING * -1, 'auto'),
            top: matchPx('bottom', placement, SPACING * -1, 'auto'),
            right: matchPx('left', placement, SPACING * -1, 'auto'),
            left: matchPx('right', placement, SPACING * -1, 'auto'),
            borderBottomWidth: matchPx('top', placement, 0, SPACING),
            borderTopWidth: matchPx('bottom', placement, 0, SPACING),
            borderRightWidth: matchPx('left', placement, 0, SPACING),
            borderLeftWidth: matchPx('right', placement, 0, SPACING),
            borderTopColor: match('top', placement, borderColor, 'transparent'),
            borderBottomColor: match('bottom', placement, borderColor, 'transparent'),
            borderLeftColor: match('left', placement, borderColor, 'transparent'),
            borderRightColor: match('right', placement, borderColor, 'transparent'),
        }, ref: ref }, rest)));
});
const Wrapper = forwardRef(function Wrapper(_a, ref) {
    var { placement, borderColor, hidden } = _a, rest = __rest(_a, ["placement", "borderColor", "hidden"]);
    return (jsx(Box, Object.assign({ sx: {
            display: hidden ? 'none' : 'inline-block',
            background: 'background',
            marginTop: matchPx('bottom', placement, SPACING + 2, 0),
            marginLeft: matchPx('right', placement, SPACING + 2, 0),
            marginRight: matchPx('left', placement, SPACING + 2, 0),
            filter: `
  drop-shadow(0px 5px 5px rgba(0,0,0,0.05))
  drop-shadow(0 1px 3px rgba(0,0,0,0.1))
`,
            borderRadius: 8,
            fontSize: 12,
            border: `1px solid ${borderColor}`,
        }, ref: ref }, rest)));
});

/**
 * Popover container that is triggered by a click/hover event, using [react-popper-tooltip](https://github.com/mohsinulhaq/react-popper-tooltip).
 */
const Popover = (_a) => {
    var { arrowVisible = true, trigger, placement = 'bottom', modifiers, tooltip, children, tooltipShown, onVisibilityChange } = _a, rest = __rest(_a, ["arrowVisible", "trigger", "placement", "modifiers", "tooltip", "children", "tooltipShown", "onVisibilityChange"]);
    const borderColor = 'lightgrey';
    const theme = useTheme();
    return (React.createElement(TooltipTrigger, { placement: placement, trigger: trigger, modifiers: modifiers, tooltipShown: tooltipShown, onVisibilityChange: onVisibilityChange, tooltip: tooltipProps => {
            const { getTooltipProps, getArrowProps, tooltipRef, arrowRef } = tooltipProps;
            const _a = getTooltipProps(), { hidden } = _a, containerProps = __rest(_a, ["hidden"]);
            return (React.createElement(Wrapper, { placement: placement, borderColor: borderColor, hidden: hidden, ref: tooltipRef, style: Object.assign(Object.assign({}, containerProps.style), { backgroundColor: get(theme.colors, 'background') }) },
                arrowVisible && (React.createElement(Arrow, { placement: placement, borderColor: borderColor, ref: arrowRef, style: Object.assign({}, getArrowProps().style) })),
                typeof tooltip === 'function' ? tooltip(tooltipProps) : tooltip));
        } }, ({ getTriggerProps, triggerRef }) => (React.createElement("div", Object.assign({ ref: triggerRef }, getTriggerProps(), { style: { display: 'inline-block' } }, rest), children))));
};

/**
 * Container component to enclose items that will provide copy functionality on click.
 *
 */
const CopyContainer = (_a) => {
    var { name, value, maxLength = 50, children } = _a, rest = __rest(_a, ["name", "value", "maxLength", "children"]);
    const [isOpen, setIsOpen] = useState(false);
    return (jsx(Popover, Object.assign({ sx: {
            cursor: 'pointer',
            position: 'relative',
        } }, rest, { trigger: "click", tooltipShown: isOpen, arrowVisible: false, onVisibilityChange: isVisible => {
            setIsOpen(isVisible);
            if (isVisible) {
                copy(value);
                window.setTimeout(() => {
                    if (isVisible) {
                        setIsOpen(false);
                    }
                }, 1500);
            }
        }, tooltip: () => (jsx(Box, { sx: {
                p: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            } },
            jsx(CheckIcon, { size: 16, sx: { color: 'green' } }),
            jsx(Box, { sx: { ml: 2, fontSize: 2 } }, `${name && name !== value ? `${name} ` : ''}copied ${value.length > maxLength
                ? `${value.substring(0, maxLength)}...`
                : value}`))) }), children));
};

const Donut = forwardRef(function Donut(_a, ref) {
    var { size = 128, strokeWidth = 2, value = 0, min = 0, max = 1, title } = _a, rest = __rest(_a, ["size", "strokeWidth", "value", "min", "max", "title"]);
    const r = 16 - strokeWidth;
    const C = 2 * r * Math.PI;
    // eslint-disable-next-line no-mixed-operators
    const offset = max !== min ? C - ((value - min) / (max - min)) * C : 0;
    return (React.createElement(Box, Object.assign({ ref: ref, as: "svg", 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        viewBox: "0 0 32 32", width: size, height: size, strokeWidth: strokeWidth, fill: "none", stroke: "currentcolor", role: "img", "aria-label": `donut graphic value ${value} - from ${min} tp ${max}`, sx: {
            color: 'primary',
        } }, rest),
        title && React.createElement("title", null, title),
        React.createElement("circle", { cx: 16, cy: 16, r: r, opacity: 1 / 8 }),
        React.createElement("circle", { cx: 16, cy: 16, r: r, strokeDasharray: C, strokeDashoffset: offset, transform: "rotate(-90 16 16)" })));
});

/**
 *
 * Displays a value with a small label
 */
const Value = (_a) => {
    var { label, value } = _a, rest = __rest(_a, ["label", "value"]);
    return (React.createElement(Box, Object.assign({ variant: "value.container" }, rest),
        typeof label === 'string' ? (React.createElement(Text, { variant: "value.label" }, label)) : (label),
        !!value && React.createElement(Text, { variant: "value.value" }, value)));
};

const profilesCache = {};
const useGithubProfile = ({ username, useremail, githubAccessToken, size = 128, }) => {
    const [profile, setProfile] = useState(profilesCache[username] || {
        login: username,
        email: useremail,
        avatar_url: useremail
            ? `https://www.gravatar.com/avatar/${md5(useremail)}?s=${size}`
            : `https://github.com/${username}`,
    });
    useEffect(() => {
        const headers = githubAccessToken
            ? {
                Authorization: `token ${githubAccessToken}`,
            }
            : undefined;
        const fetchData = async () => {
            fetch(`https://api.github.com/users/${encodeURIComponent(username)}`, {
                headers,
            })
                .then(res => res.json())
                .then(result => {
                if (!result.message) {
                    profilesCache[username] = result;
                    setProfile(profilesCache[username]);
                }
                else {
                    // could not find
                    profilesCache[username] = profile;
                    fetch(`https://api.github.com/search/users?q=${encodeURIComponent(`${username} in:name`)}`, {
                        headers,
                    })
                        .then(res => res.json())
                        .then(result => {
                        if (result.items) {
                            // if only one user with this user name is found, attach it
                            if (result.items.length === 1) {
                                profilesCache[username] = result.items[0];
                                setProfile(profilesCache[username]);
                            }
                            else if (useremail) {
                                // search for email matching the github login
                                const lcEmail = useremail.toLowerCase();
                                const match = result.items.find((item) => {
                                    var _a;
                                    return ((_a = item.email) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === lcEmail ||
                                        lcEmail.includes(item.login);
                                });
                                if (match) {
                                    fetch(`https://api.github.com/users/${encodeURIComponent(match.login)}`, {
                                        headers,
                                    })
                                        .then(res => res.json())
                                        .then(result => {
                                        profilesCache[username] = result;
                                        setProfile(profilesCache[username]);
                                    });
                                }
                            }
                        }
                    });
                }
            })
                .catch(() => {
                profilesCache[username] = profile;
            });
        };
        if (!profile.id && typeof fetch !== 'undefined') {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);
    return profile;
};

/**
 * avatar to be used in an AvatarList container
 */
const GithubAvatar = ({ username, useremail, size = 48, overlap = 0.4, fixedSize = true, githubAccessToken, }) => {
    const profile = useGithubProfile({ username, useremail, githubAccessToken });
    const profileBox = (jsx("div", { sx: { p: 2 } },
        jsx("div", { sx: { fontSize: 4, fontWeight: 'bold' } }, profile.name || profile.login),
        jsx("div", { sx: { borderBottom: '1px solid rgba(0, 0, 0, 0.125)', my: 2 } }),
        jsx("div", { sx: { display: 'flex', flexDirection: 'row' } },
            profile.avatar_url && (jsx("div", { sx: { display: 'flex', flexDirection: 'column', px: 1 } },
                jsx(Avatar, { alt: username, size: 64, src: profile.avatar_url || '', title: username }))),
            jsx("div", { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    px: 1,
                    justifyContent: 'space-between',
                } },
                profile.name && (jsx("div", { sx: { fontWeight: 'heading', fontSize: 2 } }, profile.login)),
                jsx("p", { sx: { maxWidth: 400, py: 0 } }, profile.bio),
                profile.location && (jsx("div", { sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    } },
                    jsx(LocationIcon, null),
                    jsx("div", { sx: { ml: 1 } }, profile.location))))),
        (profile.public_repos || profile.public_gists || profile.followers) && (jsx("div", { sx: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderTop: '1px solid rgba(0, 0, 0, 0.125)',
                mt: 2,
                py: 2,
            } },
            jsx(Value, { label: "repos", value: profile.public_repos }),
            jsx(Value, { label: "gists", value: profile.public_gists }),
            jsx(Value, { label: "followers", value: profile.followers })))));
    const avatar = (jsx(Avatar, { size: size, src: profile.avatar_url || '', alt: `avatar of ${username}`, title: username }));
    const imgSx = {
        maxWidth: 'unset',
        display: 'block',
        overflow: 'hidden',
        lineHeight: 1,
        width: size,
        height: size,
        verticalAlign: 'middle',
    };
    const link = profile.html_url ? (jsx(Link, { "aria-label": `visit profile of ${username}`, href: profile.html_url, sx: imgSx }, avatar)) : (jsx("div", { sx: imgSx }, avatar));
    return (jsx(Popover, { trigger: "hover", placement: "bottom-start", tooltip: () => profileBox, sx: {
            maxWidth: fixedSize ? undefined : Math.round(size * (1 - overlap)),
            transition: '0.2s ease',
            ':hover': !fixedSize
                ? {
                    maxWidth: size,
                }
                : {},
        } }, link));
};

/**
 * Avatar list that links to github profiles using rest api
 */
const GithubAvatarList = (_a) => {
    var { users, size = 32, overlap = 0.4, maxItems = 7, githubAccessToken } = _a, rest = __rest(_a, ["users", "size", "overlap", "maxItems", "githubAccessToken"]);
    const width = useMemo(() => {
        const halfSizes = Math.max(0, users.length - 2) * size * overlap;
        const expanded = Math.max(users.length, 1) * size; // the last item and the hovered items
        return halfSizes + expanded;
    }, [size, overlap, users.length]);
    const lastIndex = Math.min(maxItems, users.length) - 1;
    return (jsx(Box, Object.assign({ "aria-label": `avatars of ${users.map(user => user.username).join(', ')}`, sx: {
            display: 'flex',
            alignItems: 'center',
            width,
        } }, rest),
        users.slice(0, maxItems).map((user, index) => (jsx(GithubAvatar, { key: `avatar_item${user.username}`, size: size, githubAccessToken: githubAccessToken, username: user.username, useremail: user.useremail, overlap: overlap, fixedSize: index === lastIndex }))),
        users.length > maxItems && (jsx("div", { sx: { ml: `${Math.round(overlap * size)}px`, fontWeight: 'bold' } }, "..."))));
};

/**
 * A page header component
 */
const Header = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (jsx(Flex, Object.assign({ as: "header", variant: "header" }, rest), children));
};

/**
 * Container component that will fade/outline a label at the bottom
 *
 */
const HoverBox = (_a) => {
    var { label, children } = _a, rest = __rest(_a, ["label", "children"]);
    return (jsx(Box, Object.assign({ variant: "hoverbox.container" }, rest),
        jsx(Box, { variant: "hoverbox.inner" }, children),
        jsx(Text, { variant: "hoverbox.text", className: "hoverbox-text" }, label)));
};

/**
 *
 * Displays an information icon with markdown-enabled popup on hover
 */
const InfoTip = (_a) => {
    var { size = 18, icon, children } = _a, rest = __rest(_a, ["size", "icon", "children"]);
    const iconNode = icon || jsx(InfoIcon, { size: size });
    return (jsx(Popover, Object.assign({ trigger: "hover", placement: "bottom-start", tooltip: () => (jsx(Box, { variant: "infotip.container" }, typeof children === 'string' ? (jsx(Markdown, null, children)) : (children))) }, rest),
        jsx(Button, { "aria-label": typeof children === 'string' ? children : 'click to display info', variant: "plain", sx: { mx: 2 } }, iconNode)));
};

const LEFT_ARROW = 37;
const UP_ARROW = 38;
const RIGHT_ARROW = 39;
const DOWN_ARROW = 40;
const BACKSPACE = 8;
const TAB = 9;
const RETURN = 13;
const ESC = 27;
const SPACE = 32;
/**
 * Componet to monitor keystrokes. Can attach to child, document or window.
 */
const Keyboard = ({ target = 'children', keys, onKeyDown, children, }) => {
    const onKeyDownFn = useCallback((event) => {
        const key = event.keyCode ? event.keyCode : event.which;
        if (keys.includes(key)) {
            event.preventDefault();
            event.stopPropagation();
            onKeyDown(key);
        }
    }, [keys, onKeyDown]);
    useEffect(() => {
        if (target === 'document' && typeof document !== 'undefined') {
            document.addEventListener('keydown', onKeyDownFn);
        }
        else if (target === 'window' && typeof window !== 'undefined') {
            window.addEventListener('keydown', onKeyDownFn);
        }
        return () => {
            if (target === 'document' && typeof document !== 'undefined') {
                document.removeEventListener('keydown', onKeyDownFn);
            }
            else if (target === 'window' && typeof window !== 'undefined') {
                window.removeEventListener('keydown', onKeyDownFn);
            }
        };
    }, [onKeyDownFn, target]);
    if (target === 'children' && children) {
        return cloneElement(Children.only(children), {
            onKeyDown: onKeyDownFn,
        });
    }
    return children || null;
};

/**
 * Popover multiselect displaying checkboxes to select/unselect.
 */
const Multiselect = (_a) => {
    var { items, onChange } = _a, props = __rest(_a, ["items", "onChange"]);
    return (jsx(Popover, Object.assign({ trigger: ['click'] }, props, { tooltip: () => (jsx(Box, { variant: "multiselect.container" }, items.map(item => (jsx(Box, { key: `multi_select_${item.label}`, variant: "multiselect.item" },
            jsx(Label, null,
                jsx(Checkbox, { onChange: () => onChange(item), checked: item.selected }),
                item.label)))))) })));
};

/**
 * Pagination component to navigate previous/next page
 */
const Pagination = ({ prev, next }) => (jsx(Box, { variant: "pagination.container" },
    jsx(Box, { variant: "pagination.pagecontainer" }, prev && (jsx(Link, { variant: "pagination.link", href: prev.link },
        jsx(Box, { variant: "pagination.prev" },
            jsx(Text, { variant: "pagination.label" }, "Previous"),
            jsx(Box, { variant: "pagination.linktitle" },
                jsx(ArrowLeftIcon, null),
                jsx(Text, { variant: "pagination.pagetitle" }, prev.title)))))),
    jsx(Box, { variant: "pagination.pagecontainer" }, next && (jsx(Link, { variant: "pagination.link", href: next.link },
        jsx(Box, { variant: "pagination.next" },
            jsx(Text, { variant: "pagination.label" }, "Next"),
            jsx(Box, { variant: "pagination.linktitle" },
                jsx(Text, { variant: "pagination.pagetitle" }, next.title),
                jsx(ArrowRightIcon, null))))))));

const resetTabCounter = resetIdCounter;
/**
 * Tab heading - you should specify the title/label string as the children property. To be created inside the `<TabList />` component through the children prop.
 */
const Tab = Tab$1;
/**
 * Container for `<Tab />` headings, to be created inside the `<Tabs />` component. The list of `<Tab />` components should be passed as the children prop. */
const TabList = TabList$1;
/**
 * Panel body container, to be created inside the `<Tabs />` component through the children prop.
 */
const TabPanel = TabPanel$1;
/**
 * Tabs component with [react-tabs](https://reactcommunity.org/react-tabs/) and theme-ui styling.
 *
 */
const Tabs = (_a) => {
    var { fontSize = 1 } = _a, props = __rest(_a, ["fontSize"]);
    return (jsx(Box, { variant: "tabs", sx: {
            '.react-tabs__tab': {
                fontSize,
            },
        } },
        jsx(Tabs$1, Object.assign({}, props))));
};

const IconButton = props => (React.createElement(Button, Object.assign({ style: { paddingTop: '3px', paddingBottom: '3px' } }, props)));
/**
 * Action container with built-in collapsible panels
 *
 */
const PanelContainer = (_a) => {
    var _b, _c;
    var { actions = [], children, openTab, visibleTabs = false, background, direction } = _a, rest = __rest(_a, ["actions", "children", "openTab", "visibleTabs", "background", "direction"]);
    const [tabsIndex, setTabsIndex] = useState(undefined);
    const theme = useTheme();
    const panels = getSortedPanels(actions);
    const findPanel = useCallback((id) => {
        return panels.findIndex((p) => p.id === id || p.node === id);
    }, 
    // do not update on each panel change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(panels.map(p => p.id || p.node))]);
    useEffect(() => {
        const index = findPanel(openTab);
        setTabsIndex(index > -1 ? index : undefined);
    }, [openTab, findPanel]);
    const panelActions = useMemo(() => actions.map((panel) => {
        const index = panels.findIndex((p) => p.id === panel.id);
        return panel.panel
            ? Object.assign(Object.assign({}, panel), { node: `${tabsIndex === index ? 'close' : 'open'} ${panel.node}`, onClick: (e) => {
                    if (index < 0) {
                        return undefined;
                    }
                    if (tabsIndex === index) {
                        setTabsIndex(undefined);
                    }
                    else {
                        if (panel.onClick) {
                            const ret = panel.onClick(e);
                            if (ret === true) {
                                setTabsIndex(index);
                                return ret;
                            }
                            else if (ret === false) {
                                setTabsIndex(undefined);
                                return ret;
                            }
                        }
                        setTabsIndex(index);
                    }
                    return undefined;
                } }) : panel;
    }), [actions, tabsIndex, panels]);
    const style = {
        direction,
    };
    if (background && theme.colors) {
        style.backgroundColor = (background === 'light'
            ? theme.colors.background
            : (_c = (_b = theme.colors.modes) === null || _b === void 0 ? void 0 : _b.dark) === null || _c === void 0 ? void 0 : _c.background);
        style.backgroundImage =
            'linear-gradient(rgba(232,234,232,.3) 2px, transparent 2px), linear-gradient(90deg, rgba(232,234,232,.3) 2px, transparent 2px), linear-gradient(rgba(232,234,232,.3) 1px, transparent 0px), linear-gradient(90deg, rgba(232,234,232,.3) 1px, transparent 1px)';
        style.backgroundSize = '20px 20px';
    }
    return (React.createElement(ActionContainer, Object.assign({ plain: false, actions: panelActions }, rest),
        React.createElement("div", { style: style }, children),
        React.createElement(Collapsible, { isOpen: tabsIndex !== undefined }, panels.length === 1 ? (panels[0].panel) : (React.createElement(Tabs, { selectedIndex: tabsIndex || 0, onSelect: (index) => setTabsIndex(index) },
            React.createElement(TabList, { hidden: !visibleTabs, style: {
                    textAlign: 'right',
                } }, panels.map((panel) => (React.createElement(Tab, { key: `playground_tab_${panel.node}` }, panel.node)))),
            panels.map((panel) => (React.createElement(TabPanel, { key: `playground_panel_${panel.node}` }, panel.panel))))))));
};

/**
 * Progress indicator with a label
 *
 */
const ProgressIndicator = ({ value, max, color, }) => (jsx(Box, { sx: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    } },
    jsx(Progress, { value: value, max: max, color: color }),
    jsx(Text, { sx: { fontSize: 0 } }, value)));

/**
 * an input component combined with a popover, can be used for incremental search.
 */
const SearchInput = (_a) => {
    var { onSearch, items = [], children, onSelect, popoverProps, render = rendered => rendered } = _a, rest = __rest(_a, ["onSearch", "items", "children", "onSelect", "popoverProps", "render"]);
    const [selected, setSelected] = useState(undefined);
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState(undefined);
    const updateIsOpen = (newIsOpen) => {
        // if first time open, send a onSearch message to collect items
        if (newIsOpen && search === undefined && items.length === 0) {
            onSearch('');
        }
        else {
            setIsOpen(newIsOpen && items.length > 0);
        }
    };
    const hasSearch = !!search;
    useEffect(() => {
        setIsOpen(items.length > 0 && hasSearch);
    }, [items, hasSearch]);
    const updateSearch = async (newSearch) => {
        await onSearch(newSearch);
        setSearch(newSearch);
    };
    const selectItem = (item, index, close) => {
        setSelected(index);
        if (isOpen && close) {
            if (typeof onSelect === 'function') {
                onSelect(item);
            }
            updateIsOpen(false);
        }
        else {
            updateIsOpen(true);
        }
    };
    const onKeyPressed = (key) => {
        switch (key) {
            case DOWN_ARROW:
                const downIndex = Math.min((selected || -1) + 1, items.length - 1);
                if (downIndex >= 0) {
                    selectItem(items[downIndex], downIndex, false);
                    const itemEl = typeof document !== 'undefined' &&
                        document.getElementById(`search_item_${downIndex}`);
                    if (itemEl) {
                        scrollIntoView(itemEl, { block: 'end', scrollMode: 'if-needed' });
                    }
                }
                break;
            case UP_ARROW:
                const upIndex = Math.max((selected || items.length) - 1, 0);
                if (upIndex < items.length) {
                    selectItem(items[upIndex], upIndex, false);
                    const itemEl = typeof document !== 'undefined' &&
                        document.getElementById(`search_item_${upIndex}`);
                    if (itemEl) {
                        scrollIntoView(itemEl, { block: 'start', scrollMode: 'if-needed' });
                    }
                }
                break;
            case RETURN:
                if (typeof selected === 'number' && (selected || 0) < items.length) {
                    selectItem(items[selected], selected, true);
                }
                break;
            case TAB:
            case ESC:
            default:
                updateIsOpen(false);
                break;
        }
    };
    return (jsx(Keyboard, { keys: [DOWN_ARROW, UP_ARROW, RETURN, ESC, TAB], onKeyDown: onKeyPressed },
        jsx(Popover, Object.assign({ trigger: "none", placement: "bottom", onVisibilityChange: (isVisible) => {
                updateIsOpen(isVisible);
            }, tooltip: () => render(jsx(Box, { variant: "searchinput.popover" }, jsx(Box, { as: "ul", variant: "searchinput.list" }, items.map((item, index) => {
                const props = {
                    item,
                    index,
                    isOpen,
                    search: search || '',
                    selected,
                    selectItem,
                };
                return (jsx(Box, Object.assign({ key: `search_item_${item.id || index}`, id: `search_item_${index}`, variant: "searchinput.item", as: "li", className: selected === index ? 'active' : undefined, onClick: e => {
                        e.preventDefault();
                        e.stopPropagation();
                        selectItem(item, index, true);
                    } }, rest), children ? children(props) : item.label || item));
            })))) }, popoverProps, { tooltipShown: isOpen }),
            jsx("div", { sx: { position: 'relative' } },
                jsx(Input, Object.assign({ "aria-label": "type some text to start searching", value: search || '', onBlur: () => {
                        setTimeout(() => {
                            updateIsOpen(false);
                        }, 200);
                    }, onClick: () => updateIsOpen(!isOpen), onChange: e => updateSearch(e.target.value), sx: { pl: 4, py: 2, lineHeight: 'normal' } }, rest)),
                jsx(Box, { as: SearchIcon, sx: {
                        position: 'absolute',
                        left: '8px',
                        top: 0,
                        bottom: 0,
                        margin: 'auto',
                    } })))));
};

const defColor$1 = '#227fc0';
/**
 * A container component to display label/value pairs, where the value is colord. Similar design to github shields.
 */
const Shield = (_a) => {
    var _b, _c;
    var { label, value, percent = false, color = defColor$1 } = _a, rest = __rest(_a, ["label", "value", "percent", "color"]);
    const theme = useTheme();
    const textColor = (_b = theme.colors) === null || _b === void 0 ? void 0 : _b.text;
    const bgColor = (_c = theme.colors) === null || _c === void 0 ? void 0 : _c.background;
    let resolvedValueBgColor = getColor(theme, color);
    const parsedValue = percent && value
        ? typeof value === 'number'
            ? value
            : parseFloat(value)
        : undefined;
    if (typeof parsedValue === 'number' && theme.colors) {
        resolvedValueBgColor = mix(Math.min(parsedValue / 100, 1), theme.colors.status_passed, theme.colors.status_failed);
    }
    else if (typeof resolvedValueBgColor !== 'string') {
        resolvedValueBgColor = defColor$1;
    }
    const labelBgColor = 'mutedText';
    let resolvedLabelBgColor = getColor(theme, labelBgColor);
    if (typeof resolvedLabelBgColor !== 'string') {
        resolvedLabelBgColor = '#69768C';
    }
    return (jsx(Box, Object.assign({}, rest, { variant: "shield.container" }),
        jsx(Box, { variant: "shield.left", sx: {
                bg: resolvedLabelBgColor,
                color: tinycolor
                    .mostReadable(resolvedLabelBgColor, [bgColor, textColor])
                    .toString('hex'),
            } }, label),
        jsx(Box, { variant: "shield.right", sx: {
                backgroundColor: resolvedValueBgColor,
                color: tinycolor
                    .mostReadable(resolvedValueBgColor, [bgColor, textColor])
                    .toString('hex'),
            } }, typeof parsedValue === 'number' ? `${parsedValue}%` : value)));
};

const SidebarContext = createContext({
    SidebarToggle: () => null,
    SidebarClose: () => null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCollapsed: () => { },
    responsive: false,
});
const SidebarContextProvider = ({ children, collapsible = true, }) => {
    const [collapsed, setCollapsed] = useState(undefined);
    const size = useBreakpointIndex({ defaultIndex: 2 });
    useEffect(() => {
        if (collapsible) {
            setCollapsed(size <= 1);
        }
    }, [size, collapsible]);
    const SidebarToggle = (_a) => {
        var { icon } = _a, rest = __rest(_a, ["icon"]);
        return collapsible ? (React.createElement(Button, Object.assign({ "aria-label": collapsed ? 'Expand side bar' : 'Collapse side bar', onClick: () => setCollapsed(!collapsed), sx: {
                background: 'none',
                boxShadow: 'none',
                cursor: 'pointer',
                color: 'text',
            } }, rest), icon || React.createElement(ThreeBarsIcon, { size: "medium" }))) : null;
    };
    const SidebarClose = (_a) => {
        var { icon } = _a, rest = __rest(_a, ["icon"]);
        return collapsible ? (React.createElement(Button, Object.assign({ "aria-label": collapsed ? 'Expand side bar' : 'Collapse side bar', onClick: () => setCollapsed(true), sx: {
                background: 'none',
                boxShadow: 'none',
                cursor: 'pointer',
                color: 'text',
            } }, rest), icon || React.createElement(XIcon, { size: "medium" }))) : null;
    };
    return (React.createElement(SidebarContext.Provider, { value: {
            collapsed,
            setCollapsed,
            SidebarToggle,
            SidebarClose,
            collapsible,
            responsive: size <= 1,
        } }, children));
};

/**
 * Collapsible side bar component
 */
const Sidebar = (_a) => {
    var { title, children, variant } = _a, rest = __rest(_a, ["title", "children", "variant"]);
    const toggleContext = useContext(SidebarContext);
    const { collapsed, responsive, setCollapsed } = toggleContext || {};
    const theme = useTheme();
    return collapsed ? null : (jsx(Box, Object.assign({ variant: responsive ? 'sidebar.responsive' : 'sidebar.default', sx: get(theme, variant), onClick: () => responsive && setCollapsed(true) }, rest),
        jsx(Box, { variant: 'sidebar.headercontainer' }, title && (jsx(Box, { as: "header" }, typeof title === 'string' ? (jsx(Heading, { as: "h3", variant: 'sidebar.heading' }, title)) : (title)))),
        jsx(Box, { variant: "sidebar.inner" }, children)));
};

/**
 * single skip link anchor item
 */
const SkiLinksItem = (_a) => {
    var { target, text } = _a, rest = __rest(_a, ["target", "text"]);
    return (jsx("a", Object.assign({}, rest, { href: `#${target}`, "data-skip-link": "true" }), text));
};
/**
 * list of anchor elements to skip to
 *
 */
const SkipLinks = ({ items }) => (jsx(Box, { variant: "skiplinks.container", as: "section", "aria-label": "skip tab order to linked items" }, items.map((item, idx) => (jsx(Box, { variant: "skiplinks.item", key: `skip_link_${idx}` },
    jsx(SkiLinksItem, Object.assign({}, item)))))));

/**
 * `h2` level heading with faded text and font-weight 400.
 */
const Subtitle = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (React.createElement(Heading, Object.assign({ as: "h2", variant: "subtitle" }, rest), children));
};

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, itemsLabel }) => {
    const count = preGlobalFilteredRows.length;
    return (jsx(Flex, { sx: {
            flexDirection: 'row',
            alignItems: 'center',
            mr: 3,
        } },
        jsx(Label, { sx: {
                mr: 3,
                width: 'auto',
                color: 'mutedText',
            } }, "search:"),
        jsx(Input, { value: typeof globalFilter === 'string' ? globalFilter : '', onChange: e => {
                setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }, placeholder: `${count} ${itemsLabel}...` })));
};

/* eslint-disable react/display-name */
const useControlledState = (state) => {
    return useMemo(() => {
        var _a;
        if ((_a = state === null || state === void 0 ? void 0 : state.groupBy) === null || _a === void 0 ? void 0 : _a.length) {
            return Object.assign(Object.assign({}, state), { hiddenColumns: [
                    ...state.hiddenColumns,
                    ...state.groupBy,
                ].filter((d, i, all) => all.indexOf(d) === i) });
        }
        return state;
    }, [state]);
};
const useExpanderColumn = (itemsLabel) => (hooks) => {
    hooks.useControlledState.push(useControlledState);
    hooks.visibleColumns.push((columns, { instance }) => {
        if (!instance.state.groupBy
            .length) {
            return columns;
        }
        return [
            {
                id: 'expander',
                width: 20,
                Header: () => null,
                Cell: ({ row, }) => {
                    var _a;
                    if (row.canExpand) {
                        return (jsx("td", { colSpan: row.cells.length },
                            jsx(Flex, Object.assign({ variant: "styles.tdgroup" }, row.getToggleRowExpandedProps()),
                                row.isExpanded ? (jsx(ChevronDownIcon, null)) : (jsx(ChevronRightIcon, null)),
                                ' ',
                                jsx(Text, { sx: {
                                        mx: 2,
                                    } }, (_a = row.groupByVal) !== null && _a !== void 0 ? _a : '',
                                    itemsLabel
                                        ? `(${row.subRows.length} ${itemsLabel})`
                                        : ''))));
                    }
                    return null;
                },
            },
            ...columns,
        ];
    });
};

/* eslint-disable react/display-name */
const IndeterminateCheckbox = forwardRef(function IndeterminateCheckbox({ onChange, checked }, ref) {
    return (jsx(Label, null,
        jsx(Checkbox, { ref: ref, onChange: onChange, checked: checked, "aria-label": `click to ${checked ? 'un-' : ''}select row` })));
});
const useRowSelectionColumn = (hooks) => {
    hooks.visibleColumns.push(columns => [
        {
            id: 'selection',
            width: 30,
            Header: ({ getToggleAllRowsSelectedProps, }) => (jsx(IndeterminateCheckbox, Object.assign({}, getToggleAllRowsSelectedProps()))),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (jsx("div", null,
                jsx(IndeterminateCheckbox, Object.assign({}, row.getToggleRowSelectedProps())))),
        },
        ...columns,
    ]);
};

function useTableLayout(hooks) {
    hooks.getHeaderProps.push(getHeaderProps);
    hooks.getCellProps.push(getCellProps);
}
useTableLayout.pluginName = 'useTableLayout';
const getHeaderProps = (props, { column }) => [
    props,
    {
        style: {
            width: isNaN(column.totalWidth) ? column.width : `${column.totalWidth}px`,
        },
    },
];
const getCellProps = (props, { cell }) => {
    return [
        props,
        {
            style: {
                width: isNaN(cell.column.totalWidth)
                    ? cell.column.width
                    : `${cell.column.totalWidth}px`,
            },
        },
    ];
};

const runtimeTemplate = (str, obj) => str.replace(/\${(.*?)}/g, (x, g) => obj[g]);
const TablePagination = props => {
    const { totalCountVisible = true, totalCountTemplate = 'Total: ${totalData} records', data = [], gotoPage, canPreviousPage, previousPage, nextPage, canNextPage, pageCount, setPageSize, pageIndex = 0, pageSize = 10, pageOptions, pageTemplate = 'Page ${pageIndex} of ${pageLength}', pageVisible = false, pageSizeTemplate = '${pageSize} rows', pageSizeVisible = false, goToPageVisible = false, goToPageTemplate = 'Go to page:', } = props;
    const pageResolvedTemplate = runtimeTemplate(pageTemplate, {
        pageIndex: pageIndex + 1,
        pageLength: pageOptions.length,
    });
    const totalCountResolvedTemplate = runtimeTemplate(totalCountTemplate, {
        totalData: data.length,
    });
    return (jsx(Box, { variant: "table.pagination.container" },
        jsx(Box, { variant: "table.pagination.total" }, totalCountVisible && totalCountResolvedTemplate),
        jsx(Box, { variant: "table.pagination.navigationContainer" },
            (canPreviousPage || canNextPage) && (jsx(Box, { variant: "table.pagination.navigation" },
                jsx(Box, { variant: "table.pagination.navigation.button" },
                    jsx(Button, { onClick: () => gotoPage(0), disabled: !canPreviousPage }, '<<')),
                jsx(Box, { variant: "table.pagination.navigation.button" },
                    jsx(Button, { onClick: () => previousPage(), disabled: !canPreviousPage }, '<')),
                jsx(Box, { variant: "table.pagination.navigation.button" },
                    jsx(Button, { onClick: () => nextPage(), disabled: !canNextPage }, '>')),
                jsx(Box, { variant: "table.pagination.navigation.button" },
                    jsx(Button, { onClick: () => gotoPage(pageCount - 1), disabled: !canNextPage }, '>>')))),
            pageVisible && (jsx(Box, { variant: "table.pagination.page" }, pageResolvedTemplate)),
            goToPageVisible && (jsx(Box, { variant: "table.pagination.interactive" },
                goToPageTemplate,
                jsx(Input, { type: "number", placeholder: "jump to page...", defaultValue: pageIndex + 1, onChange: e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                        gotoPage(page);
                    } }))),
            pageSizeVisible && (jsx(Box, { variant: "table.pagination.pagesize" },
                jsx(Select, { "aria-label": "select number of rows per page", value: pageSize, onChange: e => {
                        setPageSize(Number(e.target.value));
                    } }, [10, 20, 30, 40, 50].map(pageSize => (jsx("option", { key: pageSize, value: pageSize }, runtimeTemplate(pageSizeTemplate, {
                    pageSize,
                }))))))))));
};

const defaultColumn = memoize(() => ({
    subRows: undefined,
    accessor: '',
}));
/**
 * Table component using [react-table](https://github.com/tannerlinsley/react-table) to display the data.
 * Can be grouped, filtered, sorted. Themed with theme-ui for consistency.
 */
function Table(_a) {
    var { columns, data = [], header = true, sorting = false, filtering = false, itemsLabel = 'properties', groupBy, expanded, hiddenColumns, skipPageReset, renderRowSubComponent, initialSelected = {}, onSelectRowsChange, rowSelect, sortBy, pagination } = _a, rest = __rest(_a, ["columns", "data", "header", "sorting", "filtering", "itemsLabel", "groupBy", "expanded", "hiddenColumns", "skipPageReset", "renderRowSubComponent", "initialSelected", "onSelectRowsChange", "rowSelect", "sortBy", "pagination"]);
    const plugins = [
        useTableLayout,
        useGlobalFilter,
        useGroupBy,
        useSortBy,
        useExpanded,
        useExpanderColumn(itemsLabel),
    ];
    const initialState = {};
    if (Array.isArray(groupBy)) {
        initialState.groupBy = groupBy;
        initialState.hiddenColumns = hiddenColumns || groupBy;
    }
    else if (hiddenColumns !== undefined) {
        initialState.hiddenColumns = hiddenColumns;
    }
    if (Array.isArray(sortBy)) {
        initialState.sortBy = sortBy;
    }
    if (typeof expanded === 'object') {
        initialState.expanded = expanded;
    }
    if (pagination) {
        plugins.push(usePagination);
        if (typeof pagination === 'object') {
            if (typeof pagination.pageIndex === 'number') {
                initialState.pageIndex = pagination.pageIndex;
            }
            if (typeof pagination.pageSize === 'number') {
                initialState.pageSize = pagination.pageSize;
            }
        }
    }
    if (rowSelect) {
        plugins.push(useRowSelect);
        plugins.push(useRowSelectionColumn);
    }
    initialState.selectedRowIds = initialSelected;
    const options = {
        columns,
        data,
        defaultColumn: defaultColumn(),
        initialState,
        autoResetPage: !skipPageReset,
        autoResetExpanded: !skipPageReset,
        autoResetGroupBy: !skipPageReset,
        autoResetSelectedRows: !skipPageReset,
        autoResetSortBy: !skipPageReset,
        autoResetFilters: !skipPageReset,
        autoResetRowState: !skipPageReset,
    };
    const tableOptions = useTable(options, ...plugins);
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, visibleColumns, preGlobalFilteredRows, setGlobalFilter, state, rows, page, // Instead of using 'rows', when using pagination
    state: { pageIndex: statePageIndex, pageSize: statePageSize }, } = tableOptions;
    const { selectedRowIds } = state;
    useEffect(() => {
        if (onSelectRowsChange) {
            onSelectRowsChange(selectedRowIds);
        }
    }, [selectedRowIds, onSelectRowsChange]);
    return (jsx(Fragment, null,
        jsx(Box, Object.assign({ as: "table", variant: "styles.table" }, getTableProps(), rest),
            header && (jsx(Box, { as: "thead", variant: "styles.thead" },
                headerGroups.map((headerGroup) => (jsx(Box, Object.assign({ as: "tr" }, headerGroup.getHeaderGroupProps()), headerGroup.headers.map((column) => (jsx(Box, Object.assign({ as: "th", variant: "styles.th" }, column.getHeaderProps(sorting ? column.getSortByToggleProps() : undefined)),
                    jsx(Flex, { sx: {
                            flexDirection: 'row',
                            alignItems: 'center ',
                        } },
                        jsx(Box, { sx: { mr: 1, flex: 1 } }, column.render('Header')),
                        sorting &&
                            column.isSorted &&
                            (column.isSortedDesc ? (jsx(TriangleDownIcon, null)) : (jsx(TriangleUpIcon, null)))))))))),
                filtering && (jsx(Box, { as: "tr", variant: "styles.thead.tr" },
                    jsx(Box, { as: "th", variant: "styles.th", 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        colSpan: visibleColumns.length, sx: {
                            textAlign: 'left',
                        } },
                        jsx(GlobalFilter, { itemsLabel: itemsLabel, preGlobalFilteredRows: preGlobalFilteredRows, globalFilter: state.globalFilter, setGlobalFilter: setGlobalFilter })))))),
            jsx(Box, Object.assign({ as: "tbody", variant: "styles.tbody" }, getTableBodyProps()), (page || rows).map((row) => {
                prepareRow(row);
                const _a = row.getRowProps(), { key } = _a, rowProps = __rest(_a, ["key"]);
                return (jsx(Fragment, { key: key },
                    jsx(Box, Object.assign({ variant: "styles.tr", as: "tr" }, rowProps), row.isGrouped
                        ? row.cells[0].render('Aggregated')
                        : row.cells.map((cell) => {
                            return (jsx(Box, Object.assign({ as: "td", variant: "styles.td" }, cell.getCellProps()), cell.render('Cell')));
                        })),
                    row.isExpanded && row.original && (jsx("tr", null,
                        jsx("td", { colSpan: visibleColumns.length }, renderRowSubComponent
                            ? renderRowSubComponent({ row })
                            : null)))));
            }))),
        pagination && (jsx(TablePagination, Object.assign({}, pagination, { pageIndex: statePageIndex, pageSize: statePageSize }, tableOptions)))));
}

const defColor = 'white';
/**
 * A container component to display text in a colored box, with a semi-transparent background.
 */
const Tag = (_a) => {
    var { children, raw, color = defColor, borderSize = 2, transparentAmount = 0.85, variant } = _a, rest = __rest(_a, ["children", "raw", "color", "borderSize", "transparentAmount", "variant"]);
    const theme = useTheme();
    let resolvedColor = getColor(theme, color);
    if (typeof resolvedColor !== 'string') {
        resolvedColor = defColor;
    }
    return (jsx(Box, Object.assign({ as: "span", variant: "tag.default" }, rest, { sx: Object.assign({ backgroundColor: transparentize(resolvedColor, transparentAmount), border: `${borderSize}px solid ${resolvedColor}` }, get(theme, variant)) }), raw ? (jsx(Text, { dangerouslySetInnerHTML: { __html: raw } })) : (jsx(Text, null, children))));
};

const isActive = (item, active) => active ? item.id === active.id || item.label === active.label : false;
const hasActiveChidlren = (item, active) => {
    if (isActive(item, active)) {
        return true;
    }
    return item.items
        ? item.items.some(t => hasActiveChidlren(t, active))
        : false;
};
const expandTreeItems = (items, expanded) => items.reduce((acc, item) => {
    return [
        ...acc,
        Object.assign(Object.assign({}, item), { expanded, items: item.items ? expandTreeItems(item.items, expanded) : [] }),
    ];
}, []);
const getTreeItemsStats = (items) => items.reduce((totals, item) => {
    const { expanded, items } = item;
    const itemTotals = {
        total: 1,
        expanded: expanded ? 1 : 0,
    };
    if (items) {
        const { total, expanded } = getTreeItemsStats(items);
        itemTotals.total = itemTotals.total + total;
        itemTotals.expanded = itemTotals.expanded + expanded;
    }
    return {
        total: totals.total + itemTotals.total,
        expanded: totals.expanded + itemTotals.expanded,
    };
}, { total: 0, expanded: 0 });
const getExpandedItems = (items, active) => items.reduce((expandedItems, item) => {
    const { items, expanded } = item;
    if (expanded || hasActiveChidlren(item, active)) {
        expandedItems.push(item);
    }
    if (items) {
        return expandedItems.concat(getExpandedItems(items, active));
    }
    return expandedItems;
}, []);
const getCollapsibleItems = (items) => items.reduce((collapsibleItems, item) => {
    const { items } = item;
    let childrenCollapsibleItems = [];
    if (items) {
        collapsibleItems.push(item);
        childrenCollapsibleItems = getCollapsibleItems(items);
    }
    return collapsibleItems.concat(childrenCollapsibleItems);
}, []);
const getFlatChildrenIds = (children) => children
    ? children.reduce((flatChildren, item) => {
        flatChildren.push(item);
        if (item.items) {
            // eslint-disable-next-line no-param-reassign
            flatChildren = flatChildren.concat(getFlatChildrenIds(item.items));
        }
        return flatChildren;
    }, [])
    : [];
const getChildrenById = (children, id) => {
    if (!children || id) {
        return undefined;
    }
    let items;
    children.some(item => {
        if (item.id === id || item.label === id) {
            ({ items } = item);
            return true;
        }
        if (item.items) {
            items = getChildrenById(item.items, id);
            if (items) {
                return true;
            }
        }
        return false;
    });
    return items;
};
const filterItems = (items, search) => {
    if (search && search.length) {
        const searchLC = search.toLowerCase();
        return items
            .map(item => Object.assign({}, item))
            .filter(item => {
            const { items: children, label } = item;
            if (typeof label === 'string' &&
                label.toLowerCase().indexOf(searchLC) >= 0) {
                return true;
            }
            if (children) {
                const childItems = filterItems(children, search);
                // eslint-disable-next-line no-param-reassign
                item.items = childItems;
                if (childItems.length) {
                    return true;
                }
            }
            return false;
        });
    }
    return items;
};
const stateFromProps = ({ items, expandAll, activeItem, search, }) => {
    const filteredItems = filterItems(items, search);
    const collapsibleItems = getCollapsibleItems(filteredItems);
    let expandedItems;
    if (expandAll || (search && search.length)) {
        expandedItems = collapsibleItems;
    }
    else {
        expandedItems = getExpandedItems(filteredItems, activeItem);
    }
    const allExpanded = typeof expandAll !== 'undefined'
        ? expandAll
        : collapsibleItems.length === expandedItems.length;
    return {
        expandedItems,
        items,
        filteredItems,
        search,
        collapsibleItems,
        allExpanded,
        expandAll,
        originalExpandAll: expandAll,
    };
};

/**
 * Hierarchical collapsible tree structure
 */
const Tree = (_a) => {
    var { items, expandAll, activeItem, search, onSelect, onExpandCollapse, arrowPosition = 'end', chevronIcon = jsx(ChevronDownIcon, null), rowLinkClass, indentPixels = 8 } = _a, rest = __rest(_a, ["items", "expandAll", "activeItem", "search", "onSelect", "onExpandCollapse", "arrowPosition", "chevronIcon", "rowLinkClass", "indentPixels"]);
    const [state, setState] = useState(stateFromProps({
        items,
        expandAll,
        activeItem,
        search,
    }));
    useEffect(() => {
        setState(stateFromProps({
            items,
            expandAll,
            activeItem,
            search,
        }));
    }, [items, expandAll, activeItem, search]);
    const onMenuChange = (item, expanded) => {
        const { expandedItems, filteredItems } = state;
        let newExpandedItems = [...(expandedItems || [])];
        if (expanded) {
            const id = item.id || (typeof item.label === 'string' ? item.label : '');
            const toBeCollapsed = [
                id,
                ...getFlatChildrenIds(getChildrenById(filteredItems, id)),
            ];
            newExpandedItems = newExpandedItems.filter(item => toBeCollapsed.indexOf(item.id || (typeof item.label === 'string' ? item.label : '')) < 0);
        }
        else {
            newExpandedItems.push(item);
        }
        setState(Object.assign(Object.assign({}, state), { expandedItems: newExpandedItems }));
        if (typeof onExpandCollapse === 'function') {
            onExpandCollapse(newExpandedItems.length);
        }
    };
    const renderItem = (item, level = 0) => {
        const { expandedItems } = state;
        const { items: itemItems, id, label, widget, icon } = item, rest = __rest(item, ["items", "id", "label", "widget", "icon"]);
        const itemId = id || label;
        const isExpanded = expandedItems && itemId ? expandedItems.includes(item) : false;
        const LinkClass = rowLinkClass || (itemItems ? Button : Link);
        const itemKey = `item_${itemId}_${level}`;
        const isActiveItem = activeItem && activeItem.id === id;
        const isActiveParent = hasActiveChidlren(item, activeItem);
        const expandIcon = (itemItems === null || itemItems === void 0 ? void 0 : itemItems.length) ? (jsx(Box, { variant: "tree.expandicon", sx: {
                transform: isExpanded ? undefined : 'rotate(-90deg)',
            } }, chevronIcon)) : null;
        const content = (jsx(Flex, { sx: {
                background: isActiveItem ? 'active' : undefined,
                pl: `${level * indentPixels}px`,
            } },
            jsx(Flex, { variant: "tree.itemcontainer" },
                jsx(Flex, { sx: { flexDirection: 'row', alignItems: 'center', flex: 1 }, className: isActiveItem && !itemItems ? 'selected' : undefined },
                    jsx(LinkClass, Object.assign({ "aria-expanded": (itemItems === null || itemItems === void 0 ? void 0 : itemItems.length) ? (isExpanded ? true : false) : undefined, variant: "tree.link" }, rest, { onClick: (e) => {
                            if (itemItems) {
                                e.stopPropagation();
                                onMenuChange(item, isExpanded);
                            }
                            else if (typeof onSelect === 'function') {
                                onSelect(item);
                            }
                        } }),
                        jsx(Flex, { sx: { flexDirection: 'row', justifyContent: 'space-between' } },
                            jsx(Box, { variant: "tree.labelcontainer" },
                                arrowPosition === 'start' ? expandIcon : null,
                                icon && jsx(Box, { variant: "tree.labelicon" }, icon),
                                typeof label === 'string' ? (jsx(Text, { variant: "tree.labeltext", sx: {
                                        color: isActiveParent ? 'primary' : 'text',
                                    } }, itemItems ? jsx("strong", null, label) : label)) : typeof label === 'function' ? (label(Object.assign({ isExpanded }, item))) : (label)),
                            jsx(Flex, { sx: { flexDirection: 'row', alignItems: 'center', px: 2 } },
                                widget && jsx(Box, { variant: "tree.widget" }, widget),
                                arrowPosition === 'end' ? expandIcon : null)))))));
        return (jsx(Box, { key: itemKey },
            itemItems ? (jsx(Keyboard, { keys: [LEFT_ARROW, UP_ARROW, RIGHT_ARROW, DOWN_ARROW], onKeyDown: key => onMenuChange(item, [DOWN_ARROW, RIGHT_ARROW].includes(key)) }, content)) : (content),
            itemItems &&
                isExpanded &&
                itemItems.map(child => renderItem(child, level + 1))));
    };
    const { filteredItems } = state;
    return (jsx(Box, Object.assign({}, rest), filteredItems && filteredItems.map(item => renderItem(item, 1))));
};

/**
 * zoom effect using transform
 */
const Zoom = ({ scale = 1, children }) => {
    return (React.createElement(Box, { css: {
            overflow: scale === 1 ? 'inherit' : 'hidden',
        } },
        React.createElement(Box, { variant: "zoom", css: {
                transform: `scale(${scale})`,
            } }, children)));
};

export { ActionBar, ActionContainer, BACKSPACE, BlockContainer, Collapsible, ColorMode, CopyContainer, DOWN_ARROW, Description, Donut, ESC, ExternalLink, GithubAvatar, GithubAvatarList, Header, HoverBox, IconButton, InfoTip, Keyboard, LEFT_ARROW, Link, LinkContextProvider, LinkHeading, Markdown, Multiselect, PageContainerDefaults, Pagination, PanelContainer, Popover, ProgressIndicator, RETURN, RIGHT_ARROW, SPACE, SearchInput, Shield, Sidebar, SidebarContext, SidebarContextProvider, SkiLinksItem, SkipLinks, Source, Subtitle, SyntaxHighlighter, TAB, Tab, TabList, TabPanel, Table, Tabs, Tag, ThemeContext, ThemeProvider, TitledImage, Toggle, Tree, UP_ARROW, Value, Zoom, expandTreeItems, getAccentPaletteColor, getChildrenById, getFlatChildrenIds, getPaletteColor, getSortedActions, getSortedPanels, getTreeItemsStats, hasActiveChidlren, markdownComponents, pageLink, resetTabCounter, stateFromProps, theme, titleToId, useGetLinkClass, useIsLocalLink, useTheme };
